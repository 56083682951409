.App {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
}

.App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

.App-title {
    font-size: 2.5rem;
    margin: 0;
}

.App-subtitle {
    font-size: 1.2rem;
    margin: 10px 0 20px;
}

.App-main {
    padding: 20px;
    background-color: #f9f9f9;
}

.App-description,
.App-slogan {
    margin: 20px auto;
    max-width: 600px;
    line-height: 1.5;
}

.download-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.download-option {
    margin: 10px 20px;
    text-align: center;
}

.platform-logo {
    height: 80px;
    margin-bottom: 10px;
}

.download-link {
    display: block;
    width: 150px;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
}

.download-link:hover {
    background-color: #0056b3;
}

.App-footer {
    background-color: #fff;
    padding: 20px;
    margin-top: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.icp-info {
    text-align: center;
}

.icp-info a {
    color: #007BFF;
    text-decoration: none;
}

.icp-info a:hover {
    text-decoration: underline;
}

.icp-info span {
    margin: 0 5px;
}